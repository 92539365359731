import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import { ModalContacts } from "./ModalContacts";

export const UserAccount: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);
    
    const handleClickLogout = () => {
        navigate('/login');
    }

    const handleClickContacts = () => {

    }

    const handleShow = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        // setTimeout(() => {
            setShow(false);
        // }, 300);
    }

    return (
        <>
            <img src="/images/fl-user.svg" className="home-icon-top" height={40} onClick={handleShow} />

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Body className="p-0">
                    <div className="d-flex flex-column p-3 pb-4 account-border-bottom">
                        <button className="btn btn-modal-header" onClick={handleClose} />
                                
                        <div className="account-avatar-wrapper mb-3">
                            <img src="/images/account-avatar.svg" width={124} height={124} />
                            <img src="/images/fl-btn-profile-add.svg" className="profile-add btn-profile-add" /> {/* data-bs-toggle="modal" data-bs-target="#uploadProfile" */}
                        </div>
                        
                        <div className="user-account-details d-flex flex-column gap-1">
                            <p>{commonData.firstName} {commonData.lastName}</p>
                            <p>You’re on a Free Plan</p>
                        </div>
                    </div>
                    <div className="user-account-options d-flex gap-3 p-3 account-border-bottom">
                        <img src="/images/account-cog.svg" />
                        <p>Account Settings</p>
                    </div>
                    <div className="user-account-options d-flex gap-3 p-3 account-border-bottom">
                        <img src="/images/account-checklist.svg" />
                        <p>Payment Methods</p>
                    </div>
                    <div onClick={handleClickLogout} className="user-account-options d-flex gap-3 p-3 account-border-bottom">
                        <img src="/images/account-logout.svg" />
                        <p>Logout</p>
                    </div>
                    <ModalContacts />
                    
                </Modal.Body>
            </Modal>
        </>
    );
}