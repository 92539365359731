import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialType, UpdateFamilyStoryType } from "../AppType";
import { Modal } from "react-bootstrap";
import { postFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';

type ModalProps = {
    memorialState: MemorialType,
}

export const ModalCreateFamilyStory: React.FC<ModalProps> = (props) => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");

    const txtAreaRef = useRef<HTMLTextAreaElement>(null!);

    const formData: UpdateFamilyStoryType = {
        familyStory: "",
    }

    const [formState, setFormState] = useState<MemorialType>(memorialData);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    const handleFormChange = (e: React.ChangeEvent<any>) => {
        const value = e.target.value;

        if (value.length <= 240) {
            setCharactersRemaining(240 - value.length);

            if (value.length < 240) {
                setCharactersRemainingText(" characters remaining");
            } else {
                setCharactersRemainingText(" characters max");
            }

            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: value
            }));
        }
    }

    const handleShow = () => {
        setModalSuccess(false);
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        // setTimeout(() => {
            setShow(false);
        // }, 300);
    }

    const handleReset = () => {
        setModalFail("");
    }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}`,
            formState,
            (response: any) => {
                setModalSuccess(true);
                console.log("handlePost Success Response:", JSON.stringify(response));

                setMemorialData((memorialData) => ({
                    ...memorialData,
                    familyStory: formState.familyStory
                }));
                
            },
            (error: any) => { // error
                setModalFail(JSON.stringify(error));
            }
        );
    }

    const inputs = document.querySelectorAll('textarea[data-expandable]');

    if (inputs) {
        inputs.forEach((textarea, i) => {
            textarea.addEventListener('input', function(e) {

                const textAreaInput = e.target as HTMLTextAreaElement;
                textAreaInput.style.removeProperty('height');
                textAreaInput.style.height = (textAreaInput.scrollHeight+2) + 'px';
            });
        });
    }

    return (
        <>
            <div className="btn-light" onClick={handleShow} style={{height: "41px !important", cursor: "pointer"}}>
                <p className="btn-txt">{ t("Timeline.addObituary", {ns: "components"}) }</p>
            </div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">Write a family story</p>
                    <div className="btn btn-modal-close" onClick={handleClose} style={{zIndex: 999}}><img src="/images/modal-close-x.svg"/></div>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-container">
                        <div className="form-floating">
                            <textarea ref={txtAreaRef} data-expandable id="familyStory" value={formState.familyStory} onChange={handleFormChange} maxLength={240} className="form-control memory-message-input family-story" style={{height: "calc(100vh - 179px) !important"}} placeholder={`Write a summary of ${memorialData.firstName}’s family story.`} />
                            <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-modal-footer" onClick={handlePost}>Save</button>
                </Modal.Footer>

                {modalSuccess && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Family story added</p>
                                <p></p>
                            </div>
                        </div>

                        <button onClick={handleClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>
                }

                {modalFail !== "" && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Failed :(</p>
                                <p>Error: {modalFail}</p>
                            </div>
                        </div>

                        <button onClick={handleReset} className="btn fl-btn-modal-bottom button-absolute">Try Again</button>
                    </div>
                }
            </Modal>
        </>
    );
}





