import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ForeverLovedDaysType, MemorialTab, MemorialType, PageResultType } from "../AppType";
import { useTranslation } from "react-i18next";
import { fetchRecords, debug, ASSET_URL, BASE_URL } from "../../utils/AppUtil";
import { AppContext } from "../../AppContext";
import { UserAccount } from "../Modals/ModalUserAccount";
import { ModalCreateMemorial } from "../Modals/ModalCreateMemorial";
import { AddToHome } from "../Modals/ModalAddToHome";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';

type HomeProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
    handleNavClick: (pageId: MemorialTab) => void
}

export const Home: React.FC<HomeProps> = (props) => {

    //BUILD UPDATE
    const [buildNum, setBuildNum] = useState<number>(2);
    
    // Internationalisation
    const { t, i18n } = useTranslation();

    // Authentication data from AppContext
    const { commonData } = useContext(AppContext)!;
    
    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [memorials, setMemorials] = useState<React.ReactElement[]>();
    const [stories, setStories] = useState<React.ReactElement[]>();
    const [foreverLovedDays, setForeverLovedDays] = useState<React.ReactElement[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [emptyState, setEmptyState] = useState<boolean>(false)
    
    const [language, setLanguage] = useState<string>("en");
    
    const gbFlag: string = "/images/flags/flag-gb.svg";
    const frFlag: string = "/images/flags/flag-fr.svg";
    const [currentFlag, setCurrentFlag] = useState(gbFlag);

    const [splashShow, setSplashShow] = useState<boolean>(false);
    const [splashAnimation, setSplashAnimation] = useState('');

    const [FLDays, setFLDays] = useState<boolean>(false);

    // const changeLanguage = () => {
    //     if(language === "en") {
    //         setLanguage("fr");
    //         setCurrentFlag(frFlag)
    //         i18n.changeLanguage("fr");
    //     } else {
    //         setLanguage("en");
    //         setCurrentFlag(gbFlag)
    //         i18n.changeLanguage("en");
    //     }
    // }

    // HOMEPAGE DISPLAY ----------
    useEffect(() => {

        fetchRecords(commonData.token, 'memorial/homepage', processMemorialData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });

        fetchRecords(commonData.token, 'user/loggedIn/dates', processForeverLovedDaysData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });
        
        fetchRecords(commonData.token, 'user/loggedIn/dates', processStoryData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'Home');
        });

    }, [memorialData, language]);

    // Simulate click to combat mobile touch swipe bug
    useEffect(() => {
        document.getElementById("discoverCommunitiesSlide1")?.click();
    }, []);
    

    const processMemorialData = (responseJson: PageResultType) => {
        setIsLoading(true);
        setMemorials(buildMemorialsJSX(responseJson));
        setIsLoading(false);

        if (responseJson.numRecords === 0) {
            setEmptyState(true);
        }
    }

    const buildMemorialsJSX = (response: PageResultType): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        response.data.forEach((memorial: MemorialType) => {

            let profilePictureSrc = "images/fl-avatar-min.svg";
            if (memorial.memorialPictureId) {
                profilePictureSrc = `${ASSET_URL}/public/picture/${memorial.memorialPictureId}`
            }

            let backgroundImg = "/images/covor_photo_default_Small.png";
            if (memorial.coverPictureId) {
                backgroundImg = `${ASSET_URL}/public/picture/${memorial.coverPictureId}`
            }

            result.push(

                <div key={key} className="home-memorial-tile" style={{cursor: "pointer"}}>

                    {memorial.role != "VIEWER" && 
                        <div className="home-tile-badge">
                            {memorial.role === "CREATOR" &&
                                <p>{t("Home.Memorials.roleOwner", {ns: "components"})}</p>
                            }
                            {memorial.role === "ADMIN" &&
                                <p>{t("Home.Memorials.roleAdmin", {ns: "components"})}</p>
                            }
                            {memorial.role === "EDITOR" &&
                                <p>{t("Home.Memorials.roleEditor", {ns: "components"})}</p>
                            }
                        </div>
                    }

                    <div onClick={() => {setMemorialData(memorial); props.handleNavClick(MemorialTab.tab1);}} 
                        className="home-memorial-tile-body d-flex flex-column align-items-center p-3 memorial-tile-profile-cover"
                        style={{backgroundImage: `linear-gradient(rgba(0, 34, 47, 0) -100%, rgb(6, 45, 60) 50%), url('${backgroundImg}')`}}
                    >
                        <div className="memorial-tile-profile-picture-wrapper mb-3">
                            <img src={profilePictureSrc} className="memorial-tile-profile-picture" width="148" height="148" alt="" />
                        </div>
                        <p>{memorial.firstName + ' ' + memorial.lastName}</p>
                        <p>{memorial.birthYear} - {memorial.deathYear}</p>
                    </div>
                    
                </div>
            );
            key++;
        });

        return result;
    }

    const processForeverLovedDaysData = (responseJson: ForeverLovedDaysType[]) => {
        if (responseJson.length === 0) {
            setFLDays(true);
        } else {
            setFLDays(false);
        }

        setIsLoading(true);
        setForeverLovedDays(buildForeverLovedDaysJSX(responseJson));
        setIsLoading(false);
    }

    const buildForeverLovedDaysJSX = (response: ForeverLovedDaysType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;

        const today = (new Date()).getTime();
        const msDay = 24 * 60 * 60 * 1000; // milliseconds per day
        
        let date;
        let formattedDate;

        response.forEach((day: ForeverLovedDaysType) => {

            date = (new Date(day.date)).getTime();
            formattedDate = Math.floor((date - today) / msDay);

            let dayColor = "";
            let dayText = "";

            if (day.dateType === "BIRTH") {
                dayColor = "#198754";
                dayText = "Heavenly Birthday";

            } else if (day.dateType === "DEATH") {
                dayColor = "#FD9843";
                dayText = "Day of Passing";

            } else if (day.dateType === "YAHRZEIT") {
                dayColor = "#8540F5";
                dayText = "Yahrzeit";

            } else if (day.dateType === "FATHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Father's Day";

            } else if (day.dateType === "MOTHERS_DAY") {
                dayColor = "#D63384";
                dayText = "Mother's Day";
            }

            let pictureSrc = "images/fl-days-avatar.svg";
            if (day.memorialPictureId) {
                pictureSrc = `${ASSET_URL}/public/picture/${day.memorialPictureId}`
            }

            result.push(
                <div key={key} className="fl-day" style={{background: `linear-gradient(to bottom right, ${dayColor}1A 50%, #052734 100%) 100% no-repeat`}}>
                    <div className="d-flex gap-3">
                        <img src={pictureSrc} width={64} height={64} />

                        <div className="d-flex flex-column justify-content-center gap-1">
                            <p className="fl-day-name">{day.userFirstname}<br/>{day.userLastname}'s</p>
                            <p className="fl-day-relation">{day.relationship}</p>

                            

                        </div>
                    </div>

                    <div className="w-100">
                        <div className="fl-day-type" style={{backgroundColor: dayColor + '33'}}>
                            <div className="d-flex align-items-center gap-2">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "2px"}}>
                                    <g clipPath="url(#clip0_10976_74125)">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.00008 0.985539C9.32858 -2.43596 17.6506 3.55129 6.00008 11.25C-5.65042 3.55204 2.67158 -2.43596 6.00008 0.985539Z" fill={dayColor}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_10976_74125">
                                            <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <div className="d-flex flex-column">
                                    <p>{dayText}</p>

                                    {formattedDate === 0 ?
                                        <p className="fl-day-date"><span>Today</span></p>
                                        :
                                        <p className="fl-day-date">in <span>{formattedDate} days</span></p>
                                    }
                                </div>
                            </div>

                            {day.dateType === "YAHRZEIT" &&

                                <>
                                    <div className="dropdown-center pt-2" style={{position: 'static'}}>

                                        {/* data-bs-display="static" */}
                                        <img className="dropdown-toggle" src="/images/fl-icon-info.svg" data-bs-toggle="dropdown" aria-expanded="false" />

                                        <div className="dropdown-menu yahrzeit-dropdown">
                                            <img src="/images/fl-dropdown-triangle.svg" />
                                            <p className="mb-1"><b>What is a Yahrzeit?</b></p>
                                            <p>A Jewish tradition marking the anniversary of a loved one's death, observed with prayers, memorial candles, and acts of remembrance.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const processStoryData = (responseJson: ForeverLovedDaysType[]) => {
        setIsLoading(true);
        setStories(buildStoriesJSX(responseJson));
        setIsLoading(false);
    }

    const buildStoriesJSX = (response: ForeverLovedDaysType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;        

        response.forEach((story: ForeverLovedDaysType) => {

            let pictureSrc = "images/fl-days-avatar.svg";
            if (story.memorialPictureId) {
                pictureSrc = `${ASSET_URL}/public/picture/${story.memorialPictureId}`
            }

            result.push(
                <div className="story-tile-wrapper">
                    <div key={key} className="story-tile" style={{backgroundImage: `url(${pictureSrc})`}}>
                        <div className="story-image-wrapper p-2">
                            <img src={pictureSrc} width={72} height={72} />
                        </div>
                    </div>

                    <div className="story-title">
                        <p>{story.memorialFirstName}</p>
                        <p>{story.memorialLastName}</p>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }


    return (
        <>
            <AddToHome />

            {splashShow &&
                <div className={`splash-loading-div ${splashAnimation}`}>
                    <img src="/images/splash-logo-new.svg" className="mb-3"  alt="" />
                    <p>forever loved</p>
                    <p>Keep their stories alive</p>
                </div>
            }

            <div className="page-wrapper home">

                <div className="home-header">
                    <p onClick={() => window.location.reload()}>{ t("Home.title", {ns: "components"}) }</p>
                    <div className="d-flex gap-3">
                        <UserAccount />
                        <img src="/images/fl-icon-heart-1.svg" className="home-icon-top" height={40} />
                        <img src="/images/fl-icon-search.svg" className="home-icon-top" height={40} />
                    </div>
                </div>

                <div className="stories-wrapper pe-3 mb-3">
                    <div className="story-tile-wrapper">
                        <div className="story-tile add-memory">
                            <img src={`images/btn-add-story.svg`} className="btn-add-story" />
                            <div className="story-image-wrapper p-2">
                                <img src="images/fl-days-avatar.svg" width={72} height={72} />
                            </div>
                        </div>

                        <div className="story-title">
                            <p>Add a</p>
                            <p>memory</p>
                        </div>
                    </div>

                    {stories}
                </div>

                {FLDays ?
                    <>
                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Introducing Forever Loved Days</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        <div className="p-3 mb-3">
                            <div className="card-container-top d-flex justify-content-center">
                                <img src="/images/fl-days-video.png" width="100%" />
                            </div>

                            <div className="card-container-bottom" style={{backgroundColor: '#FFFFFF0D'}}>
                                <div className="d-flex flex-column gap-3">
                                    <p className="fl-days-intro">Turn on contact uploading to celebrate more Forever Loved days</p>
                                    <div className="d-flex gap-3">
                                        <div className="fl-days-btn-1"><p>Not now</p></div>
                                        <div className="fl-days-btn-2"><p>Turn on</p></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="d-flex gap-3">
                            <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Reminders</p>
                            <div className="home-memorials-heading-line">&nbsp;</div>
                        </div>

                        <div className="fl-days-wrapper pe-3 mb-3">

                            {foreverLovedDays}

                            <div className="fl-day fl-day-add">
                                <div className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
                                    <img src="/images/fl-logo-small.svg" />
                                    <p className="add-connections-txt">Add connections to see more <b>Forever Loved days</b></p>
                                </div>
                                <div className="btn fl-btn-dark w-100 fw-bold" style={{padding: "12px"}}>Add connections</div>
                            </div>
                        </div>
                    </>
                }

                

                <div className="d-flex gap-3">
                    <p className="fl-txt-home-subheading ps-3" style={{whiteSpace: "nowrap"}}>Memorials</p>
                    <div className="home-memorials-heading-line">&nbsp;</div>
                </div>

                <div className="d-flex flex-column justify-content-between">
                    {!emptyState ?
                        <div className="home-memorials d-flex flex-column gap-3 p-3 h-100">
                            {memorials}
                            <ModalCreateMemorial emptyState={false} fromAccountCreate={false} gender={""} relation={""} link={""} />
                        </div>
                        :
                        <div className="card card-no-memorials m-3">
                            <img src="/images/img-no-memorials.png" className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="card-text d-flex flex-column gap-2 mb-4">
                                    <p>Memorials</p>
                                    <p>Remember your loved ones with memorials that honour their enduring legacy.</p>
                                </div>
                                <div className="btn btn-light btn-light-txt w-100" style={{padding: '11px'}}>Create a memorial</div>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </>
    );
}