import React, { useCallback, useContext, useEffect, useState } from "react";
import { MemorialTab, MemorialType } from "../components/AppType";
import { Header } from "../components/Header/Header";
import { SetupWidget } from "../components/SetupWidget/SetupWidget";
import { TreeOfLife } from "../components/TreeOfLife/TreeOfLife";
import { Menu } from "../components/Menu/Menu";
import { LifeStory } from "../components/LifeStory/LifeStory";
import { Tombstone } from "../components/Tombstone/Tombstone";
import { Home } from "../components/Home/Home";
import { AppContext } from "../AppContext";
import { CSSTransition } from 'react-transition-group';
import { MemorialContext } from "../MemorialContext";
import { useTranslation } from "react-i18next";
import { ModalCreateEvent } from "../components/Modals/ModalCreateEvent";
import { FamilyTree } from "../components/FamilyTree/FamilyTree";
import { scrollTop } from "../utils/AppUtil";

export const MemorialJourney: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Active tab represents the current selected tab. Default is home
    const [activeTab, setActiveTab] = useState<MemorialTab>(MemorialTab.home);
    const isActiveTab = (pageId: MemorialTab): number => {
        const active: number = 1;
        const inactive: number = 0;
        if (activeTab === pageId) {
            return active;
        }
        return inactive;
    }

    // This is the Memorial Setup Widget to display creation progress
    const [setupStatusComplete, setSetupStatusComplete] = useState<boolean>(false);

    // This is for Life Event or Memory image compression/uploading status
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);
    const [croppingFiles, setCroppingFiles] = useState<boolean>(false);
    const [uploadCount, setUploadCount] = useState<number>(0);
    const [uploadTotal, setUploadTotal] = useState<number>(0);
    const [uploadFail, setUploadFail] = useState<string | null>(null);

    const [refreshCount, setRefreshCount] = useState<number>(0);

    const [popupAnimation, setPopupAnimation] = useState<string>('');

    useEffect(() => {
        scrollTop();
    }, [activeTab]);

    useEffect(() => {

    }, [uploadingFiles])

    const handleFileUploading = (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => {
        if (uploadStatus) {
            setUploadingFiles(true);
        }

        if (croppingStatus) {
            setCroppingFiles(true);
        } else {
            setCroppingFiles(false);
        }

        setUploadCount(count);
        setUploadTotal(total);

        console.log('STATUS: ', uploadStatus);
        console.log('COUNT: ', count);
        console.log('TOTAL: ', total);
        console.log('Updating uploadFail state:', error);

        if (!uploadStatus && !croppingStatus) {
            if (error !== null) {
                setUploadFail(JSON.stringify(error));
                setTimeout(() => {
                    setPopupAnimation('animate__animated animate__fadeOutDown animate__faster');
                }, 5000);
                setTimeout(() => {
                    setUploadingFiles(false);
                    setPopupAnimation('');
                }, 6000);
                
                console.log('ERROR ---->', error);
                return;

            } else {
                console.log('ERROR? -> ', error);
                setPopupAnimation('animate__animated animate__fadeOutDown animate__faster');
                setTimeout(() => {
                    setUploadingFiles(uploadStatus);
                    setPopupAnimation('');
                }, 1000);
            }
        }
        
    }

    const handleNavClick = (pageId: MemorialTab) => {
        setActiveTab(pageId);
    }

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    // Increment the refresh count to trigger a refresh
    const handleRefresh = useCallback(() => {
        setRefreshCount(prevCount => prevCount + 1);
        handleClose();
    }, []);

    return(
        <>
            <div id="pageWrapper" className="page-wrapper">

                {isActiveTab(MemorialTab.home) == 1 &&
                    <CSSTransition in={true} timeout={2000} appear={true} classNames="transitionFade">
                        <Home activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab} handleNavClick={handleNavClick} />
                    </CSSTransition>
                }

                {isActiveTab(MemorialTab.home) !== 1 &&
                    <>
                        <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                            <Header activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab} />
                        </CSSTransition>

                        {setupStatusComplete &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <SetupWidget />
                            </CSSTransition>
                        }
                        
                        <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                            <Menu activeTab={activeTab} setActiveTab={setActiveTab} isActiveTab={isActiveTab}  handleNavClick={handleNavClick} />
                        </CSSTransition>

                        {isActiveTab(MemorialTab.tab1) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <LifeStory onShowCreateEventModal={handleShow} refreshCount={refreshCount} />
                            </CSSTransition>
                        }

                        {isActiveTab(MemorialTab.tab2) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <FamilyTree />
                            </CSSTransition>
                        }

                        {isActiveTab(MemorialTab.tab3) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <TreeOfLife />
                            </CSSTransition>
                        }

                        {isActiveTab(MemorialTab.tab4) === 1 &&
                            <CSSTransition in={true} out={true} timeout={2000} appear={true} classNames="transitionFade">
                                <Tombstone />
                            </CSSTransition>
                        }
                    </>
                }

                <ModalCreateEvent show={showModal} onHide={handleClose} onFileUpload={handleFileUploading} onEventCreated={handleRefresh} />  

                {(uploadingFiles || croppingFiles) &&
                    <div className={`image-uploading-counter ${popupAnimation}`}>
                        {(uploadFail !== null && uploadFail !== "") ? 
                            <p>Failed!</p>
                            :
                            <>
                                <p>{croppingFiles ? 'Cropping' : 'Uploading'} images</p>
                                <div className="d-flex gap-2">
                                    <p>{uploadCount} of {uploadTotal}</p>

                                    <div className="spinner-border text-secondary" role="status" style={{width: "21px", height: "21px"}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }

            </div>        
        </>
    );
}