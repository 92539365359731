import UAParser from "ua-parser-js";
import { Dispatch, SetStateAction, useEffect } from "react";
import { MemorialTab } from "../AppType";

type MenuProps = {
    activeTab: MemorialTab,
    setActiveTab: Dispatch<SetStateAction<MemorialTab>>,
    isActiveTab:  (pageId: MemorialTab) => number,
    handleNavClick: (pageId: MemorialTab) => void
}

export const Menu:React.FC<MenuProps> = (props) => {

    // Device detection --------------------
    const parser = new UAParser();

    const getDevice = parser.getDevice().vendor;
    const getOS = parser.getOS().name;
    const getBrowser = parser.getBrowser().name;
    const getEngine = parser.getEngine().name;

    let device = false;
    if (getOS === "Mac OS" || getOS === "iOS") {
        device = true;
    }
    // -------------------------------------

    useEffect(() => {

    }, [props.activeTab]);

    return (
        <>
            <div className="memorial-footer">
                <div className="nav nav-pills d-flex justify-content-evenly align-items-center" id="pills-tab" role="tablist">

                    <div id="pageLifeStory" onClick={() => {props.handleNavClick(MemorialTab.tab1)}} className={`nav-item tab-bg${props.isActiveTab(MemorialTab.tab1) === 1 ? "-active" : ""}`} role="presentation">
                        <div className="tab-icon-wrapper">
                            <img src="/images/tab-icon-life-story.svg" />
                            <p className={`tab-text ${props.isActiveTab(MemorialTab.tab1) === 1 ? "fw-bold" : ""}`}>Life Story</p>
                        </div>
                    </div>

                    <div id="pageFamilyTree" onClick={() => {props.handleNavClick(MemorialTab.tab2)}} className={`nav-item tab-bg${props.isActiveTab(MemorialTab.tab2) === 1 ? "-active" : ""}`} role="presentation">
                        <div className="tab-icon-wrapper">
                            <img src="/images/tab-icon-family-tree.svg" />
                            <p className={`tab-text ${props.isActiveTab(MemorialTab.tab2) === 1 ? "fw-bold" : ""}`}>Family Tree</p>
                        </div>
                    </div>

                    <div id="pageTreeOfLife" onClick={() => { props.handleNavClick(MemorialTab.tab3)} } className={`nav-item tab-bg${props.isActiveTab(MemorialTab.tab3) === 1 ? "-active" : ""}`} role="presentation">
                        <div className="tab-icon-wrapper">
                            <img src="/images/tab-icon-donate-a-leaf.svg" />
                            <p className={`tab-text ${props.isActiveTab(MemorialTab.tab3) === 1 ? "fw-bold" : ""}`}>Donate a Leaf</p>
                        </div>
                    </div>

                    <div id="pageTombstone" onClick={() => {props.handleNavClick(MemorialTab.tab4)}} className={`nav-item tab-bg${props.isActiveTab(MemorialTab.tab4) === 1 ? "-active" : ""}`} role="presentation">
                        <div className="tab-icon-wrapper">
                            <img src="/images/tab-icon-tombstone.svg" />
                            <p className={`tab-text ${props.isActiveTab(MemorialTab.tab4) === 1 ? "fw-bold" : ""}`}>Tombstone</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}