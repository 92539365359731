import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { CreateLifeEvent } from "./CreateLifeEvent";
import { CreateMemory } from "./CreateMemory";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';

type ModalProps = {
    onFileUpload: (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => void;
    show: boolean;
    onHide: () => void;
    onEventCreated: () => void;
}

export const ModalCreateEvent: React.FC<ModalProps> = ({onFileUpload, show, onHide, onEventCreated}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalClass, setModalClass] = useState<string>('')
    const [cardOneAnimation, setCardOneAnimation] = useState<string>('')
    const [cardTwoAnimation, setCardTwoAnimation] = useState<string>('')
    const [eventSelected, setEventSelected] = useState<number>(0);
    const [selectLifeEvent, setSelectLifeEvent] = useState<boolean>(false);
    const [selectMemory, setSelectMemory] = useState<boolean>(false);
    const selectedBorder: string = "2px solid white";

    const [lockScroll, setLockScroll] = useState<boolean>(false);

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (lockScroll) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
    }, [lockScroll, show]);

    useEffect(() => {
        setEventSelected(0);
        setCardOneAnimation('');
        setCardTwoAnimation('');
        setSelectLifeEvent(false);
        setSelectMemory(false);
        setModalClass('');
        handleLifeEventClick();
    }, [show]);

    const handleModalHide = () => {
        setModalClass('animate__animated animate__fadeOut animate__faster');
        
        setTimeout(() => {
            setModalClass('modal-hide');
        }, 1000);
    }

    const handleFileUpload = (uploadStatus: boolean, croppingStatus: boolean, count: number, total: number, error: Error | null) => {
        onFileUpload(uploadStatus, croppingStatus, count, total, error);

        setLockScroll(false);
        
        if (!uploadStatus) {
            onHide();
            setTimeout(() => {
                onEventCreated();
            }, 500);
        }
    }

    const handleLifeEventClick = () => {
        setSelectLifeEvent(true);
        setSelectMemory(false);
    }

    const handleMemoryClick = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);
    }

    const handleEventSelected = () => {
        if (selectLifeEvent) {
            setCardOneAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(1);
            }, 500);
        } else if (selectMemory) {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }
    }

    const triggerLifeEvent = (value: string) => {
        setTimeout(() => {
            setCardOneAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const triggerMemory = (value: string) => {
        setTimeout(() => {
            setCardTwoAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const handleChangeEvent = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);

        setTimeout(() => {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }, 1000);
    }

    const handleClose = () => {
        setModalClass('');
        onHide();
    }

    const handleUploadReset = () => {
        onFileUpload(false, false, 0, 0, null);
    }

    return (
        <>        
            <Modal className={modalClass} show={show} fullscreen={true} onHide={handleClose} onShow={handleUploadReset} backdrop={false}>

                <div className="modal-body-event-tier-1">
                    <div className="modal-header-bg">
                        <p className="modal-title">Add to Life Story</p>
                        <div className="btn btn-modal-close" onClick={handleClose}>
                            <img src="/images/modal-close-x.svg"/>
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-4 px-3">
                        <div className={`add-event-widget ${cardOneAnimation}`} onClick={handleLifeEventClick} style={{border: selectLifeEvent ? selectedBorder : ""}}>
                            <img src="/images/add-life-event.svg" width={23} height={28} />
                            <p className="title">Create a life event</p>
                            <p className="subtitle">These require a date (e.g. Wedding Day).</p>
                        </div>
                        
                        <div className={`add-event-widget ${cardTwoAnimation}`} onClick={handleMemoryClick} style={{border: selectMemory ? selectedBorder : ""}}>
                            <img src="/images/add-memory.svg" width={34} height={25} />
                            <p className="title">Create a memory</p>
                            <p className="subtitle">These do not require a date.</p>
                        </div>
                    </div>

                    <div className="px-3 pb-3">
                        <button className="btn btn-modal-footer" onClick={handleEventSelected}>Next</button>
                    </div>
                </div>

                {eventSelected === 1 && <CreateLifeEvent triggerLifeEvent={triggerLifeEvent} changeEvent={handleChangeEvent} onFileUpload={handleFileUpload} onModalHide={handleModalHide} />}
                {eventSelected === 2 && <CreateMemory triggerMemory={triggerMemory} onFileUpload={handleFileUpload} onModalHide={handleModalHide} />}
            </Modal>
        </>
    );
}





