import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialContext } from "../../MemorialContext";
import { ASSET_URL, debug, fetchRecords } from "../../utils/AppUtil";


export const FamilyTree: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [hideTip, setHideTip] = useState<boolean>(true);

    let memorialPictureSrc = "/images/tree-avatar-placeholder.svg";
    if (memorialData.memorialPictureId) {
        memorialPictureSrc = `${ASSET_URL}/public/picture/${memorialData.memorialPictureId}`;
    }

    // useEffect(() => {

    //     fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/familyTree`, processTreeData)
    //     .catch((error) => {;
    //         debug('fetchRecords error: ' + error, 'family_tree');
    //     });

    // }, [memorialData]);

    // const processTreeData = (responseJson: MemoryType[]) => {
    //     setMemoriesState(buildMemoriesListJSX(responseJson));
    // }

    return (
        <>
            <div className="d-flex flex-column p-3">

                {hideTip &&
                    <div className="family-tree-tip mb-3" onClick={() => setHideTip(!hideTip)}>
                        <img src="images/fl-close.svg" />
                        <p>Did you know?</p>
                        <p>People you invite to this family tree will be able to contribute to this memorial</p>
                    </div>
                }

                <div className="family-tree-card">
                    <div className="family-tree-card-header">
                        <div className="header-text">
                            <p>View {memorialData.firstName}'s tree</p>
                            <p>Invite family & friends or create memorials</p>
                        </div>

                        <img src="/images/arrows-angle-expand.svg" width={16} height={16} />
                    </div>

                    <div className="family-tree-card-body">
                        <div className="family-tree-card-line hz" />
                        <div className="family-tree-card-line vert" />
                        <img src="/images/tree-join-dot.svg" className="family-tree-card-line dot" />

                        <div className="d-flex gap-5">

                            {/* Parent left */}
                            <div className="family-tree-tile tile-user">
                                <div className="d-flex flex-column gap-2">
                                    <img src="/images/tree-avatar-placeholder.svg" />
                                    <p className="tile-name">{memorialData.firstName}'s<br/>Father</p>
                                </div>
                            </div>

                            {/* Parent right */}
                            <div className="family-tree-tile tile-placeholder">
                                <div className="d-flex flex-column gap-2">
                                    {/* <img src="/images/tree-avatar-placeholder.svg" /> */}
                                    <p className="tile-name">Mother</p>
                                </div>
                            </div>
                        </div>

                        {/* Memorial */}
                        <div className="family-tree-tile tile-memorial">
                            <div className="d-flex flex-column gap-2">
                                <img src={memorialPictureSrc} className="tile-img tile-img-border" />
                                <p className="tile-name">{memorialData.firstName}<br/>{memorialData.lastName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
}