import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";

export const LoginSuccess: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    // Disable vertical scrolling on body when modal is open
    useEffect(() => {
        const preventScroll = (e: TouchEvent) => e.preventDefault();
    
        if (show) {
            document.body.style.position = 'fixed';
            window.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        }
    
        return () => {
            document.body.style.position = '';
            window.removeEventListener('touchmove', preventScroll);
        };
      }, [show]);

    const handleShow = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
        setTimeout(() => {
            handleClose(); 
        }, 2000);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        // setTimeout(() => {
            setShow(false);
            navigate('/');
        // }, 300);
    }

    return (
        <>
            <button id="loginSuccessModal" onClick={handleShow} style={{display: "none", visibility: "hidden"}}/>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Body className="d-flex flex-column gap-2 align-items-center justify-content-center">
                    <img src="/images/fl-login-tick.svg" />
                    <div className="login-success-txt d-flex flex-column gap-1">
                        <p>Login Successful</p>
                        <p>Welcome back {commonData.firstName}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}